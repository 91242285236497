.changepasswordpass {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 30px;
    // padding: 40px 30px;
    max-width: 740px;
    margin: 0 auto;
  
    .heading {
      padding: 20px 30px;
      background: #A8A6A6;
      border-radius: 30px 30px 0px 0px;
  
      @media(max-width:479px) {
        padding: 20px 15px;
  
      }
  
      h2 {
        font-weight: 500;
        font-size: 25px;
        line-height: 30px;
        color: #fff;
        margin-bottom: 0;
      }
    }
  
  
    .loginInner {
      padding: 40px 30px;
      background: #fff;
      border-radius: 0px 0px 30px 30px;
  
      @media(max-width:479px) {
        padding: 30px 15px;
  
      }
  
      .form-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
  
        label {
          width: 140px;
  
          @media(max-width:479px) {
            width: 100%;
            margin-bottom: 10px;
          }
        }
  
        .fieldWrapper {
          flex-basis: calc(100% - 140px);
          max-width: calc(100% - 140px);
          padding-left: 20px;
  
          @media(max-width:479px) {
            flex-basis: 100%;
            max-width: 100%;
            padding-left: 0;
  
          }
  
          .rememberLoginbtn {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
  
            .btnForgotPass {
              .btn {
                margin-bottom: 11px;
              }
  
              p {
                font-size: 12px;
                line-height: 14px;
                color: #000000;
                text-align: right;
              }
            }
          }
  
          .checkboxcontainer input {
            display: none;
          }
  
          .checkboxcontainer {
            display: inline-block;
            padding-left: 30px;
            position: relative;
            cursor: pointer;
            user-select: none;
            width: auto;
            align-self: flex-start;
            line-height: 16px;
          }
  
          .checkboxcontainer .checkmark {
            display: inline-block;
            width: 17px;
            height: 15px;
            background: white;
            position: absolute;
            left: 0;
            top: 0;
            border: 1px solid black;
            border-radius: 3px;
          }
  
          .checkboxcontainer input:checked+.checkmark {
            background-color: #FF8C31;
            border: 1px solid #FF8C31;
          }
  
          .checkboxcontainer input:indeterminate+.checkmark {
            background-color: #FF8C31;
            border: 1px solid #FF8C31;
          }
  
          .checkboxcontainer input:checked+.checkmark:after {
            content: "";
            position: absolute;
            height: 5px;
            width: 9px;
            border-left: 2px solid white;
            border-bottom: 2px solid white;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
  
          .checkboxcontainer input:checked:disabled+.checkmark {
            border: 1px solid grey;
            background-color: grey;
          }
  
          .checkboxcontainer input:disabled+.checkmark {
            border: 1px solid grey;
          }
  
          .checkboxcontainer input:indeterminate+.checkmark:after {
            content: "";
            position: absolute;
            height: 0px;
            width: 11px;
            border-left: 2px solid white;
            border-bottom: 2px solid white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
          }
  
        }
      }
  
      .copyrighttxt {
        padding-top: 30px;
        font-size: 13px;
        line-height: 18px;
        text-transform: capitalize;
        color: #000000;
        text-align: center;
        max-width: calc(100% - 98px);
        margin-left: auto;
  
        @media(max-width:479px) {
          max-width: 100%;
  
        }
      }
    }
    .error_danger {
      color: red;
      font-size: 12px;
      position: absolute;
    }

    .textPassword {
      position: relative;
    }
    
    .iconPassword {
      position: absolute;
      right: 9px;
      top: 14px;
      @media(max-width:567px) {
        top: 40px;

      } 
    }
  }
  
  .Submitbtn {
    text-align: right;
  
    @media(max-width:479px) {
      text-align: center;
  
    }
    
  }