.graphsec {
  .item {
    margin-bottom: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    padding: 10px 30px;
    text-align: center;
    width: 100%;

    &.graph2 {
      img {
        width: 100%;
      }
    }
  }
}