.dataEntry {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, .13);
  border-radius: 30px;
  max-width: 700px;
  margin: 0 auto;
  padding: 50px 30px;

  .btn {
    padding: 15px 10px;
  }

  textarea {
    height: 100px;
  }
}