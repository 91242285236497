@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

// font-family: 'Raleway', sans-serif;
// font-family: 'Roboto', sans-serif;
* {
  box-sizing: border-box;
  outline: none !important;
}

a {
  transition: ease 0.3s;
}

button {
  transition: ease 0.3s;
}

.cursor-pointer {
  cursor: pointer;
}


body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 27px;
  background: #F8F9FC;
  font-size: 14px;
  line-height: 27px;

  color: #000000;

}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

a {
  color: #000000;
  text-decoration: none;
}

a:hover {
  color: #F69A51;
  text-decoration: none;
}

a:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  padding: 0;
  margin: 0 0 20px 0;
}

h1 {
  font-size: 43px;
  line-height: 52px;

}

h2 {
  font-size: 32px;
  line-height: 42px;

}

h3 {
  font-size: 18px;
  line-height: 23px;
}

h4 {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
}

h5 {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 12px;
}

h6 {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
}

p {
  margin: 0 0 20px 0;
}

p:last-child {
  margin-bottom: 0;

}

ol {
  padding-left: 20px;

}

ol li {
  margin-bottom: 5px;

}

ul {
  padding: 0;

}

ul li {
  padding: 0;
  position: relative;
  list-style: none;

}

a {
  transition: 0.3s all ease-in-out 0s;
}

.dropdown-toggle:focus {
  outline: none;
}

input:not([type='checkbox']),
input:not([type='radio']),
textarea,
.form-control,
select {
  appearance: none;
  width: 100%;
  height: 50px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  font-family: 'Roboto', sans-serif;
  background: #EEEFF0;
  border-radius: 8px;
  color: #000000;
  font-size: 12px;
  line-height: 14px;

  &:placeholder {
    color: #000000;
    opacity: 1;
  }
}

select {
  padding-right: 52px;
  background: url(./downarrow.svg) no-repeat right 0 #EEEFF0;
  background-size: 50px;
  appearance: none;

}




ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

label {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.form-group {
  margin-bottom: 20px;
}

button {
  background: transparent;
  border: 0;
}

.btn {
  padding: 12px 34px;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  background: #FF8C31;
  border-radius: 6px;
  min-width: 113px;
  border: 1px solid #FF8C31;

  &:hover {
    background: transparent;
    color: #FF8C31;
  }
}

.sidebarPageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 84vh;

  .sideBarOuter {
    width: 205px;
    background: #FFFFFF;
    box-shadow: -2px -4px 16px rgba(0, 0, 0, 0.05);
    // height: 100vh;
    padding: 30px 14px;
    transition: 0.3s all ease-in-out 0s;

    @media(min-width:1600px) {
      // height: 100vh;
    }

    @media(max-width:991px) {
      height: 100%;
      min-height: 93vh;
    }

    @media(max-width:767px) {
      position: absolute;
      left: -100%;
      top: 50px;
      z-index: 1;
      transition: 0.3s all ease-in-out 0s;
    }
  }
  .pageWrapper {
    flex-basis: calc(100% - 205px);
    max-width: calc(100% - 205px);
    padding: 40px 40px;
    padding-right: 5%;
    @media(max-width:767px) {
      flex-basis: 100%;
      max-width: 100%;
      padding: 40px 15px;
    }
  }
  &.active {
    .pageWrapper {
      flex-basis: 100%;
      max-width: 100%;
      padding: 40px 15px;
    }
    .sideBarOuter {
      display: none;

      @media(max-width:767px) {
        display: block;
      }
    }
  }
}
body {
  &.body-active {
    overflow: hidden;

    .sideBarOuter {
      left: 0;
    }
  }
}
.cp {
  cursor: pointer;
}

.paginationBttns{
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  // justify-content: center;
}
.paginationBttns a{
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #EB9E45;
  color: #EB9E45;
  cursor: pointer;
}

.paginationBttns a:hover{
  color: white;
  background-color:#EB9E45; 
}
.paginationActive a{
  color: white;
  background-color:#EB9E45; 
}