.AccordionOuter {
  .projectItem {
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    text-align: center;
    padding: 30px;
    margin-bottom: 30px;
    position: relative;
  }

  .accordion-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 30px !important;
    margin-bottom: 30px;
    border: 0;

    &:last-child {
      margin-bottom: 0;
    }

    .accordion-header {
      button {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        border-radius: 30px !important;
        background: transparent !important;
        border: 0;
        box-shadow: none;

        &:focus {
          outline: 0;
          box-shadow: none;
        }

        &::after {
          background: transparent;
          content: "+";
          font-weight: 600;
          font-size: 22px;
          line-height: 18px;
          color: #000000;
          font-family: 'Raleway', sans-serif;
          width: auto;
          height: auto;
        }

        &:not(.collapsed)::after {
          transform: inherit;
          content: "-";
        }
      }
    }

    .accordion-body {
      .playPauseRange {
        margin-bottom: 13px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;

        .css-eglki6-MuiLinearProgress-root {
          background: #C4C4C4;
          border-radius: 30px;
        }

        .MuiLinearProgress-bar {
          background: #FF8C31;
          border-radius: 30px;
        }

        button {
          padding: 0;
          margin-left: 10px;
        }
      }

      .viewHistory {
        margin-bottom: 24px;
        padding: 15px 10px;
        background: #f1f1f1;
        border-radius: 10px;

        h3 {
          margin-bottom: 8px;
          font-weight: 600;
        }
      }

      .testCaseLists {
        ul {
          li {
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .playPauseRange {
              flex-basis: 90%;
              margin-left: auto;
              margin-bottom: 0;

              @media(max-width:991px) {
                flex-basis: 80%;
              }
            }

            .viewHistory {
              margin-bottom: 0;
              margin-top: 20px;
            }

            &:last-child {
              // padding-bottom: 0;
              margin-bottom: 0;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }


.progress_bar{
  height: 8px;
  width: 250px;

  @media(max-width:567px) {
  width: 100px;
  }
  background-color: #aaa;
}
.progressed{
  height:8px;
  background-color: rgb(168,75,55);
  width: 0%;
  transition: all 0.3s;
}







.playPauseRange{

  .MuiPaper-elevation1{
    box-shadow: none !important;
  }

}
}