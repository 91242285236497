
  .settingOuter {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 30px;
    padding: 40px 30px;
    max-width: 810px;
    margin: 0 auto;
  
    @media(max-width:479px) {
      padding: 35px 15px;
    }
  
    h2 {
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 25px;
    }
    textarea {
      height: 100px;
    }
  
    .formInner {
      .form-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
  
        label {
          width: 107px;
  
          @media(max-width:479px) {
            width: 100%;
            margin-bottom: 10px;
          }
        }
  
        .fieldWrapper {
          flex-basis: calc(100% - 107px);
          max-width: calc(100% - 107px);
          padding-left: 20px;
  
          @media(max-width:479px) {
            flex-basis: 100%;
            max-width: 100%;
            padding-left: 0;
          }
  
          .fileInput {
            background: #EEEFF0;
            min-height: 50px;
            // position: relative;
            border-radius: 6px;
            padding-left: 10px;
  
            .btnFieldFile {
              display: table;
              margin-left: auto;
              position: relative;
  
              input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 50px;
                cursor: pointer;
                opacity: 0;
              }
  
              .btn {
                height: 50px;
                background: #ADACAA;
                border-color: #ADACAA;
                color: #fff;
  
                &:hover {
                  background: transparent;
                  color: #ADACAA;
                }
              }
            }
          }
        }
      }
  
      .btnwrap {
        margin-left: 127px;
  
        @media(max-width:479px) {
          margin-left: 0;
        }
      }
    }
  }

  
.multiSelect {
  .MuiSelect-icon {
    background: url(./downarrow.svg) no-repeat right 0px;
    padding-right: 50px;
    width: 50px;
    height: 60px;
    padding: 0;
    background-size: contain;
    top: 2px;
    right: 1px;
    path {
      display: none;
    }
  }

  .MuiFilledInput-root {
    background: #EEEFF0;
    border-radius: 8px;
  }

  .MuiFilledInput-input {
    padding: 14px 12px 20px 10px;
  }

  .MuiSelect-filled.MuiSelect-filled {
    padding-right: 52px;
  }
}


.checkboxcontainer input {
  display: none;
}

.checkboxcontainer {
  display: inline-block;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: auto;
  align-self: flex-start;
  line-height: 16px;
}

.checkboxcontainer .checkmark {
  display: inline-block;
  width: 17px;
  height: 15px;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid black;
  border-radius: 3px;
}

.checkboxcontainer input:checked+.checkmark {
  background-color: #FF8C31;
  border: 1px solid #FF8C31;
}

.checkboxcontainer input:indeterminate+.checkmark {
  background-color: #FF8C31;
  border: 1px solid #FF8C31;
}

.checkboxcontainer input:checked+.checkmark:after {
  content: "";
  position: absolute;
  height: 5px;
  width: 9px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.checkboxcontainer input:checked:disabled+.checkmark {
  border: 1px solid grey;
  background-color: grey;
}

.checkboxcontainer input:disabled+.checkmark {
  border: 1px solid grey;
}

.checkboxcontainer input:indeterminate+.checkmark:after {
  content: "";
  position: absolute;
  height: 0px;
  width: 11px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
}


