.projectsWrapper {
  margin-bottom: -30px;

  .projectItem {
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    text-align: center;
    padding: 30px;
    margin-bottom: 30px;
    position: relative;

    .seetingpgbtn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      background: #C7C4C4;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 50%;
      }
    }

    figure {
      background: #FDF6EE;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 15px;
      border-radius: 50%;
      @media(max-width:767px) {
        width: 70px;
        height: 70px;
      }

      img {
        width: 40%;
      }
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      text-transform: capitalize;
      font-family: 'Raleway', sans-serif;
      color: #FF8C31;
      margin-bottom: 10px;

      @media(max-width:1500px) {
        font-size: 17px;
        line-height: 21px;
      }

      @media(max-width:767px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
      color: #000000;
      margin-bottom: 0;

      @media(max-width:1500px) {
        font-size: 15px;
        line-height: 20px;
      }

      @media(max-width:767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}