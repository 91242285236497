.header {
  padding: 15px 34px;
  background: #F8F9FC;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;

  @media(max-width:767px) {
    padding: 10px 15px;
  }

  .headerInner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media(max-width:767px) {
      align-items: flex-start;
    }

    .logo {
      a {
        display: inline-block;

        @media(max-width:1500px) {
          width: 300px;
        }

        @media(max-width:767px) {
          width: 150px;
        }

        @media(max-width:350px) {
          width: 120px;
        }
      }
    }

    .testCaseName {
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      text-transform: capitalize;
      color: #000000;
      margin-bottom: 0;
      margin-right: 80px;
      margin-left: auto;
      max-width: 100%;

      @media(max-width:767px) {
        order: 1;
        margin-right: 0;
        margin-top: 20px;
        flex-basis: 100%;
        max-width: 100%;
        text-align: center;
      }
    }

    .headerRight {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @media(max-width:767px) {
        justify-content: flex-end;
      }

      @media(max-width:479px) {
        flex-basis: calc(100% - 150px);
      }

      @media(max-width:350px) {
        flex-basis: calc(100% - 120px);
      }



      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          margin-right: 13px;

          @media(max-width:767px) {
            margin-right: 10px;
          }

          &:last-child {
            margin-right: 0;
          }

          a {
            width: 48px;
            height: 48px;
            background: #C7C4C4;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              background: #000;
            }

            @media(max-width:1500px) {
              width: 35px;
              height: 35px;

              img {
                width: 18px;
                height: 18px;
                object-fit: contain;
              }
            }

            @media(max-width:767px) {
              width: 30px;
              height: 30px;

              img {
                width: 15px;
                height: 15px;
              }
            }
          }

          &.active {
            a {
              background: #FF8C31;
            }
          }
        }
      }
    }
  }

  .menubtn {
    display: none;
    padding: 0;
    width: 30px;
    height: 30px;

    background: #C7C4C4;
    border-radius: 50%;
    margin-left: 10px;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
      filter: brightness(0) invert(1);

    }

    @media(max-width:767px) {
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
}