.searchFilter {

  
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  
    .searchBar {
      position: relative;
  
      @media(max-width:575px) {
        flex-basis: 100%;
      }
  
      input {
        padding-right: 50px;
      }
  
      .btn {
        background-color: transparent;
        padding: 0;
        min-width: auto;
        border: 0;
        position: absolute;
        top: 13px;
        right: 10px;
  
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
  
    .filtersec {
      flex-basis: 200px;
  
      @media(max-width:575px) {
        flex-basis: 100%;
        margin-top: 20px;
      }
    }
  }
  
  .dataSection {
    .table-responsive {
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, .05);
      // border-radius: 20px;
      // max-width: 740px;
      margin: 0 auto;
      padding: 40px 25px;
  
      @media(max-width:767px) {
        padding: 30px 15px;
      }
  
      table {
        width: 100%;
  
        thead {
          tr {
            th {
              padding: 15px 20px;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              text-transform: capitalize;
              border: 1px solid #ccc;
  
              @media(max-width:575px) {
                padding: 15px 10px;
              }
  
              button {
                padding: 0;
  
                img {
                  width: 20px;
                }
              }
            }
          }
        }
  
        tbody {
          tr {
            td {
              padding: 15px 20px;
              font-size: 13px;
              line-height: 17px;
              text-transform: capitalize;
              border: 1px solid #ccc;
  
              @media(max-width:575px) {
                padding: 15px 10px;
              }
            }
          }
        }
      }
    }
  }
  
  .paginations {
    margin-top: 25px;
  
    .page-link {
      color: #000;
      // border-color: #FF8C31;
    }
  }
  
  .addbtn {
    margin-bottom: 20px;
  }

  