.pageNotFound {
  background: #FFFFFF;
  // box-shadow: 0px 4px 20px rgba(0, 0, 0, .13);
  // border-radius: 30px;
  // max-width: 700px;
  margin: 0 auto;
  padding: 100px 30px;
  text-align: center;

  @media(max-width:767px) {
    padding: 50px 25px;
  }

  h1 {
    font-size: 80px;
    line-height: 100px;
    margin-bottom: 40px;

    @media(max-width:767px) {
      font-size: 50px;
      line-height: 60px;
    }
  }

  h3 {
    margin-bottom: 20px;
  }

  h5 {
    margin-bottom: 35px;
  }

  .btn {
    padding: 16px 30px;
  }
}