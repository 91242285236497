.helpPg {
  max-width: 90%;

  @media(max-width:991px) {
    max-width: 100%;
  }

  h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
  }
}