
  .settingOuter {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
    border-radius: 30px;
    padding: 40px 30px;
    max-width: 810px;
    margin: 0 auto;
  
    @media(max-width:479px) {
      padding: 35px 15px;
    }
  
    h2 {
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 25px;
    }
    textarea {
      height: 100px;
    }
  
    .formInner {
      .form-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
  
        label {
          width: 107px;
  
          @media(max-width:479px) {
            width: 100%;
            margin-bottom: 10px;
          }
        }
  
        .fieldWrapper {
          flex-basis: calc(100% - 107px);
          max-width: calc(100% - 107px);
          padding-left: 20px;
  
          @media(max-width:479px) {
            flex-basis: 100%;
            max-width: 100%;
            padding-left: 0;
          }
  
          .fileInput {
            background: #EEEFF0;
            min-height: 50px;
            // position: relative;
            border-radius: 6px;
            padding-left: 10px;
  
            .btnFieldFile {
              display: table;
              margin-left: auto;
              position: relative;
  
              input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 50px;
                cursor: pointer;
                opacity: 0;
              }
  
              .btn {
                height: 50px;
                background: #ADACAA;
                border-color: #ADACAA;
                color: #fff;
  
                &:hover {
                  background: transparent;
                  color: #ADACAA;
                }
              }
            }
          }
        }
      }
  
      .btnwrap {
        margin-left: 127px;
  
        @media(max-width:479px) {
          margin-left: 0;
        }
      }
    }
  }