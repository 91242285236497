.sideBar {

  .navlinksWrapper {
    height: 70vh;

    @media(max-width:1199px) {
      height: 80vh;
    }

    @media(max-width:767px) {
      height: 73vh;
    }

    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      margin-bottom: 15px;

      @media(max-width:991px) {
        font-size: 13px;
        line-height: 18px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: block;
        width: 100%;
        padding: 16px 15px;
        border-radius: 25px;
        color: #000000;

        @media(max-width:767px) {
          padding: 13px 20px;
        }

        img {
          margin-right: 14px;

          &.active {
            display: none;
          }
        }
      }

      &.active {
        a {
          background: #FFEBD4;

          img {
            display: none;

            &.active {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .copyRight {
    font-size: 13px;
    line-height: 25px;
    text-transform: capitalize;
    color: #000000;
  }
}